import React from "react"

import Layout from "layout"
import Container from "layout/Container"
import ContactUs from "./HelpCenter/ContactUs"
import LimitationOfLiability from "./TermsAndConditions/LimitationOfLiability"
import { renderStaticPage } from "./utils/renderStaticPage"

const StaticPage = (props) => {
  const { pageContent, module } = props.pageContext
  const hasContactUs = module.title === "Help Center"
  const hasLimitationOfLiability =
    module.title === "Terms and Conditions" && module.name === "mind"

  return (
    <Layout
      seoTitle={module.seoTitle}
      display={{ helpCenterBanner: false, footer: true }}
    >
      <Container isCentered>
        <h2>{module.title}</h2>
        <hr />

        {renderStaticPage(pageContent, module.title)}
        {hasContactUs && <ContactUs module={module} />}
        {hasLimitationOfLiability && <LimitationOfLiability />}
      </Container>
    </Layout>
  )
}

export default StaticPage
